'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.Video = function Video() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $videoElement = [].slice.call(document.querySelectorAll('[action-video-play]'));

    $videoElement.forEach(function ($video) {
      $video.addEventListener('click', function (e) {
        var currentElement = e.target;
        var videoId = currentElement.dataset.videoid;
        var targetVideo = document.querySelector('#' + videoId);

        currentElement.classList.contains('has-overlay') ? targetVideo.play() : targetVideo.pause();

        currentElement.classList.toggle('has-overlay');
      });
    });
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();