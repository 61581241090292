'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Accordions = function Accordions() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  var $questions = document.querySelectorAll('.item-header[data-ref]');

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  var distanceToTop = function distanceToTop($el) {
    return Math.floor($el.getBoundingClientRect().top - document.querySelector('.main-header').offsetHeight - document.querySelector('.glossary-nav-wrapper').offsetHeight * 2);
  };

  var scrollToWord = function scrollToWord() {
    var $trigger = document.querySelector('[data-scroll-to-word]');

    if ($trigger) {
      var wordSlug = $trigger.dataset.scrollToWord;
      var targetAnchor = document.getElementById(wordSlug);
      if (!targetAnchor) return;
      var originalTop = distanceToTop(targetAnchor);

      window.scrollBy({ top: originalTop, left: 0, behavior: "smooth" });
    }
  };

  var scrollToLetters = function scrollToLetters() {
    var $triggers = [].slice.call(document.querySelectorAll('[data-scroll-to-letter]'));

    if ($triggers.length) {
      $triggers.forEach(function ($trigger) {
        $trigger.addEventListener('click', function (e) {
          e.preventDefault();

          // Scroll
          var targetAnchor = document.querySelector($trigger.getAttribute('href'));
          if (!targetAnchor) return;
          var originalTop = distanceToTop(targetAnchor);

          window.scrollBy({ top: originalTop, left: 0, behavior: "smooth" });

          // Change the active letter
          document.querySelector('.glossary-nav-link.is-active').classList.remove('is-active');
          $trigger.classList.add('is-active');
        });
      });
    }
  };

  var scrollUpdatingActiveLinks = function scrollUpdatingActiveLinks() {
    var $links = [].slice.call(document.querySelectorAll('[data-scroll-to-letter]'));

    if ($links.length) {
      var currentLetter = '#a';
      window.addEventListener('scroll', function () {
        var $topLimit = Math.floor(document.querySelector('.main-header').offsetHeight + document.querySelector('.glossary-nav-wrapper').offsetHeight + 40);

        $links.forEach(function ($link) {
          var $letterDiv = document.querySelector($link.getAttribute('href'));
          if ($letterDiv) {
            var $letterRectTop = $letterDiv.getBoundingClientRect().top;

            if ($letterRectTop <= $topLimit) currentLetter = $link.getAttribute('href');
          }
        });

        document.querySelector('.glossary-nav-link.is-active').classList.remove('is-active');
        document.querySelector('.glossary-nav-link[href="' + currentLetter + '"]').classList.add('is-active');

        if (window.window.innerWidth < 768) {
          var $letterRectRight = document.querySelector('.glossary-nav-link[href="' + currentLetter + '"]').getBoundingClientRect().right;
          var $letterRectLeft = document.querySelector('.glossary-nav-link[href="' + currentLetter + '"]').getBoundingClientRect().left;

          if ($letterRectRight > window.window.innerWidth) {
            // console.log($letterRectRight);
            document.querySelector('.glossary-nav-wrapper').scrollBy({
              behavior: 'smooth',
              left: $letterRectRight - window.window.innerWidth + 10,
              top: 0
            });
          } else if ($letterRectLeft < 0) {
            // console.log($letterRectLeft);
            document.querySelector('.glossary-nav-wrapper').scrollBy({
              behavior: 'smooth',
              left: currentLetter === 'a' ? 0 : $letterRectLeft - 10,
              top: 0
            });
          }
        }
      });
    }
  };

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;
    console.log($questions);

    if ($questions) {
      $questions.forEach(function (question) {

        question.addEventListener('click', function () {

          $questions.forEach(function (oldQuestion) {
            var oldAnswer = document.querySelector('.item-content[data-ref="' + oldQuestion.dataset.ref + '"');

            if (oldQuestion.dataset.ref !== question.dataset.ref) {
              oldQuestion.classList.remove('is-active');
              oldAnswer.classList.remove('is-active');
            } else {
              console.log('niquez tous vos meres');
            }
          });

          var answer = document.querySelector('.item-content[data-ref="' + question.dataset.ref + '"');

          if (question.classList.contains('is-active')) {

            question.classList.remove('is-active');
            answer.classList.remove('is-active');
          } else {

            question.classList.add('is-active');
            answer.classList.add('is-active');
          }
        });
      });
    }

    scrollToWord();
    scrollToLetters();
    scrollUpdatingActiveLinks();

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();