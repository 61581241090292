'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Jobs = function Jobs() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    var $fileInputs = [].slice.call(document.querySelectorAll('.file-input'));

    console.log('[Init Jobs]');

    if ($fileInputs) {
      $fileInputs.forEach(function ($fileInput) {
        $fileInput.addEventListener('change', function (e) {
          var filename = '';
          var fileLength = $fileInput.files.length;

          for (var i = 0; i < fileLength; ++i) {
            if (i == 0) {
              filename = $fileInput.files.item(i).name;
            } else {
              filename = filename + ', ' + $fileInput.files.item(i).name;
            }
          }

          var fileType = $fileInput.dataset.fileType;
          var $textInput = document.querySelector('[action-text-' + fileType + ']');

          $textInput.innerHTML = filename;
        });
      });
    }

    inited = true;

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();