'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Slider005 = function Slider005() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    // return false;

    inited = true;

    var $sliders = [].slice.call(document.querySelectorAll('.slider005'));

    if ($sliders.length) {
      setTimeout(function () {
        $sliders.forEach(function ($slider) {
          var $sliderSection = $slider.querySelector('.slider005-slider');

          var flkty = new Flickity($sliderSection, {
            cellAlign: 'left',
            resize: true,
            imagesLoaded: true,
            adaptiveHeight: true,
            groupCells: true,
            lazyLoad: true,
            wrapAround: $slider.hasAttribute('data-wraparound'),
            pageDots: $slider.hasAttribute('data-dots'),
            prevNextButtons: $slider.hasAttribute('data-prevnextbuttons'),
            autoPlay: $slider.hasAttribute('data-autoplay')
          });
        });
      }, 200);
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();