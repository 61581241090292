'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Form001 = function Form001() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // const $form = document.getElementById('contact-form');
    //
    // $form.addEventListener('submit', function(evt){
    //     evt.preventDefault();
    //
    //     Swal.fire({
    //       title: 'Successfully Registered!',
    //       text: 'Do you want to go to the Log In page?',
    //       type: 'success',
    //       showCancelButton: true,
    //       confirmButtonColor: '#3085d6',
    //       cancelButtonColor: '#d33',
    //       confirmButtonText: 'Yes, send me there!'
    //    }).then(function(result) {
    //      if (result.value) {
    //        location.assign("https://jsfiddle.net")
    //      }
    //    });
    // })

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();