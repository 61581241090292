'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.List003 = function List003() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Timeline scrolling effect
    var $timelineItems = $('.timeline-item');
    var $timeline = document.querySelector('.timeline-container');
    var $delay = 250;

    if ($timelineItems && $timeline) {
      window.addEventListener('scroll', function () {
        if ($timelineItems[0].getBoundingClientRect().top <= 300) {
          $timelineItems.forEach(function (item, index) {
            if (index === 0) {
              $timeline.classList.add('is-active');
            }

            setTimeout(function () {
              item.classList.add('is-active');
            }, $delay);

            $delay += 500;
          });
        }
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();