'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.List004 = function List004() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $blockServices = $('.block-services');
    var $menu = document.querySelector('.main-header');
    var isFixed = false;

    window.addEventListener('scroll', function () {

      $blockServices.forEach(function (item) {

        console.log(item.offsetTop);
      });
    });

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();