'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Slider003 = function Slider003() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    console.log('[Slider003]');

    var $sliders = [].slice.call(document.querySelectorAll('.slider003'));

    if ($sliders.length) {
      setTimeout(function () {
        $sliders.forEach(function ($slider) {
          var requestId1 = void 0;
          var requestId2 = void 0;

          var $sliderSection1 = $slider.querySelector('.slider003-row1');
          var $sliderSection2 = $slider.querySelector('.slider003-row2');

          var flickity1 = new Flickity($sliderSection1, {
            cellAlign: 'center',
            wrapAround: true,
            imagesLoaded: true,
            adaptiveHeight: true,
            groupCells: false,
            lazyLoad: true,
            resize: true,
            pageDots: $slider.hasAttribute('data-dots'),
            prevNextButtons: $slider.hasAttribute('data-prevnextbuttons'),
            autoPlay: $slider.hasAttribute('data-autoplay')
          });

          var flickity2 = new Flickity($sliderSection2, {
            cellAlign: 'center',
            wrapAround: true,
            imagesLoaded: true,
            adaptiveHeight: true,
            groupCells: false,
            lazyLoad: true,
            resize: true,
            pageDots: $slider.hasAttribute('data-dots'),
            prevNextButtons: $slider.hasAttribute('data-prevnextbuttons'),
            autoPlay: $slider.hasAttribute('data-autoplay')
          });

          flickity1.x = 0;
          flickity2.x = -120;

          loop1();
          loop2();

          function loop1() {
            flickity1.x = flickity1.x + 0.8;

            // Settle position into the slider
            flickity1.settle(flickity1.x);

            // Set the requestId to the local variable
            requestId1 = window.requestAnimationFrame(loop1);
          }

          function loop2() {
            flickity2.x = flickity2.x - 0.8;

            // Settle position into the slider
            flickity2.settle(flickity2.x);

            // Set the requestId to the local variable
            requestId2 = window.requestAnimationFrame(loop2);
          }
        });
      }, 200);
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();