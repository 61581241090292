'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Popup = function Popup() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $body = document.querySelector('body');

    var $modal = document.querySelector('[data-popup]');

    var $overlay = $modal.querySelector('.popup-overlay');

    var $closeBtn = $modal.querySelector('.popup-close');

    var $popupBtn = $modal.querySelector('.popup-btn');

    if (!Cookie.get('alert-popup')) {
      $modal.classList.add('is-active');
    }

    if ($modal.classList.contains('is-active')) {
      $body.classList.add('no-scroll');
    }

    if ($overlay) {
      $overlay.addEventListener('click', function () {
        $modal.classList.remove('is-active');
        $body.classList.remove('no-scroll');
        Cookie.set('alert-popup', 1, { expires: '30' });
      });
    }

    if ($closeBtn) {
      $closeBtn.addEventListener('click', function (e) {
        e.preventDefault();
        $modal.classList.remove('is-active');
        $body.classList.remove('no-scroll');
        Cookie.set('alert-popup', 1, { expires: '30' });
      });
    }

    if ($popupBtn) {
      $popupBtn.addEventListener('click', function (e) {
        e.preventDefault();
        $modal.classList.remove('is-active');
        $body.classList.remove('no-scroll');
        Cookie.set('alert-popup', 1, { expires: '30' });

        var finalUrl = $popupBtn.getAttribute('href');
        var isTargetBlank = $popupBtn.getAttribute('target');

        if (isTargetBlank) {
          window.open(finalUrl, '_blank');
        } else {
          window.location.href = finalUrl;
        }
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();