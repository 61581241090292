'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Modal = function Modal() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $activeModalBtn = document.querySelectorAll('.item-link');

    var $modals = document.querySelectorAll('.modal-wrapper');

    var $overlay = document.querySelector('.overlay');

    $activeModalBtn.forEach(function ($button) {
      $button.addEventListener('click', function (e) {
        e.preventDefault();
        $modals.forEach(function ($modal) {
          var $closeBtn = $modal.querySelector('.close-btn');

          if ($closeBtn) {
            $closeBtn.addEventListener('click', function (e) {
              e.preventDefault();

              $overlay.classList.remove('is-active');
              $modal.classList.remove('is-active');
            });
          }

          if ($modal.dataset.index === e.target.dataset.index) {
            $modal.classList.add('is-active');
            $overlay.classList.add('is-active');
          }
        });
      });
    });

    if ($overlay) {
      $overlay.addEventListener('click', function () {
        $overlay.classList.remove('is-active');
        $modals.forEach(function ($modal) {
          $modal.classList.remove('is-active');
        });
      });
    };

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();