'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.News = function News() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */
  // const $blogCatIndicator = document.querySelector('.blog-cats .blog-cat-indicator');

  var $currentBlogCat = document.querySelector('.blog-cats .blog-cat.is-active');
  var $blogCats = document.querySelectorAll('.blog-cats .blog-cat');

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // const $carousel = document.querySelector('.news-carousel');
    var $jobCarousel = document.querySelector('.block-jobs001');

    if (!$jobCarousel) {
      Flickity.createMethods.push('_createPrevNextCells');

      Flickity.prototype._createPrevNextCells = function () {
        this.on('select', this.setPrevNextCells);
      };

      Flickity.prototype.setPrevNextCells = function () {
        // remove classes
        changeSlideClasses(this.previousSlide, 'remove', 'is-previous');
        changeSlideClasses(this.previousSlide, 'remove', 'is-first-cell');
        changeSlideClasses(this.nextSlide, 'remove', 'is-next');
        // set slides
        var previousI = fizzyUIUtils.modulo(this.selectedIndex - 1, this.slides.length);
        var nextI = fizzyUIUtils.modulo(this.selectedIndex + 1, this.slides.length);
        this.previousSlide = this.slides[previousI];
        this.nextSlide = this.slides[nextI];
        // add classes
        changeSlideClasses(this.previousSlide, 'add', 'is-previous');
        changeSlideClasses(this.nextSlide, 'add', 'is-next');
      };
    }

    if ($blogCats) {
      // $blogCatIndicator.style.transform = 'translateX(' + $currentBlogCat.offsetLeft + 'px)';

      $blogCats.forEach(function (category) {
        category.addEventListener('click', function (e) {
          e.preventDefault();
          if (!category.classList.contains('is-active')) {

            $currentBlogCat = document.querySelector('.blog-cats .blog-cat.is-active');
            $currentBlogCat.classList.remove('is-active');
            category.classList.add('is-active');
            // $blogCatIndicator.style.transform = 'translateX(' + category.offsetLeft + 'px)';
            window.history.pushState("", "", category.href);

            var $ajaxZone = document.querySelector('[data-ajax-paginate="guides"]');
            var $pagination = document.querySelector('[data-ajax-pagination="guides"]');
            var $loader = '<div class="loader-container" style="height: ' + $ajaxZone.clientHeight + 'px;"><div class="loader"><svg class="circular" viewBox="25 25 50 50"><circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/></svg></div></div>';
            var apiURL = category.href;

            console.log($ajaxZone, apiURL);

            $ajaxZone.innerHTML = $loader;

            fetch(apiURL, {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              method: 'GET'
            }).then(function (resp) {
              return resp.text();
            }).then(function (data) {

              if (data) {

                // Fetch and transform response to an HTML object
                var htmlResponse = document.createElement('div');
                htmlResponse.innerHTML = data;

                var $refreshArticle = htmlResponse.querySelector('[data-ajax-paginate="' + $pagination.getAttribute('data-ajax-pagination') + '"]').innerHTML;
                $ajaxZone.innerHTML = $refreshArticle;

                $pagination.innerHTML = htmlResponse.querySelector('[data-ajax-pagination="' + $pagination.getAttribute('data-ajax-pagination') + '"]').innerHTML;
              }
            });
          }
        });
      });
    }

    return true;
  };

  var changeSlideClasses = function changeSlideClasses(slide, method, className) {
    if (!slide) {
      return;
    }
    slide.getCellElements().forEach(function (cellElem) {
      cellElem.classList[method](className);
    });
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();