'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Newsletter001 = function Newsletter001() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $cards = $('.card-item');
    var $bios = $('.card-item-full');
    var $main = document.querySelector('.overlay');
    var $closeOverlay = [].slice.call(document.querySelectorAll('[action-close-overlay]'));

    // For the newsletter form, may be generic
    var $btnTrigger = $('[data-modal-trigger]');

    $cards.forEach(function (card) {
      card.addEventListener('click', function () {

        var cardId = card.dataset.card;

        $bios.forEach(function (bio) {

          var bioId = bio.dataset.bio;

          if (bioId == cardId) {
            bio.classList.add('is-active');
            $main.classList.add('is-active');
          }

          $main.addEventListener('click', function () {
            bio.classList.remove('is-active');
            $main.classList.remove('is-active');
          });

          $closeOverlay.forEach(function ($close) {

            $close.addEventListener('click', function () {
              bio.classList.remove('is-active');
              $main.classList.remove('is-active');
            });
          });
        });
      });
    });

    // For the newsletter form, may be generic
    $btnTrigger.forEach(function (btn) {
      var btnData = btn.dataset.modalTrigger;
      var modalWindow = document.querySelector('[data-modal-window="' + btnData + '"]');
      var closeBtn = modalWindow.querySelector('[data-modal-close]');

      btn.addEventListener('click', function (e) {
        e.preventDefault();
        modalWindow.classList.add('is-active');
        $main.classList.add('is-active');
        document.querySelector('body').classList.add('not-scrollable');

        $main.addEventListener('click', function () {
          modalWindow.classList.remove('is-active');
          $main.classList.remove('is-active');
          document.querySelector('body').classList.remove('not-scrollable');
        });

        closeBtn.addEventListener('click', function (e) {
          e.preventDefault();
          modalWindow.classList.remove('is-active');
          $main.classList.remove('is-active');
          document.querySelector('body').classList.remove('not-scrollable');
        });
      });
    });

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();