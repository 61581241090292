'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.FloatingBlock = function FloatingBlock() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  var $floatState = document.querySelector('[action-float-state]');
  var $closeFloat = document.querySelector('[action-close-float]');
  var $minimizeFloat = document.querySelector('[action-minimize-float]');

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    if ($closeFloat) {
      $closeFloat.addEventListener('click', function (e) {
        e.preventDefault();
        e.target.nextElementSibling.classList.add('is-closed');
      });
    }

    if (localStorage.getItem('minimizedStatus') === 'minimized') {
      $floatState.classList.add('is-minimized');
      $minimizeFloat.textContent = '+';
    }

    if ($minimizeFloat) {
      $minimizeFloat.addEventListener('click', function (e) {
        e.preventDefault();
        e.target.nextElementSibling.classList.toggle('is-minimized');

        console.log(e.target.dataset.floatId);
        localStorage.setItem('floatingBlockId');

        if (e.target.nextElementSibling.classList.contains('is-minimized')) {
          e.target.textContent = '+';
          localStorage.setItem('minimizedStatus', 'minimized');
        } else {
          e.target.textContent = '-';
          localStorage.setItem('minimizedStatus', 'opened');
        }
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();