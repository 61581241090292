// window.site = (window.site || {});
//
// /**
//  * Init Mobile Menu related scripts
//  * @class Site
//  * @static
//  */
// site.Content001 = (function Content001() {
//
//   'use strict';
//
//   /**
//    * jQuery elements
//    * @private
//    */
//
//   /**
//    * Has the class been initialized?
//    * @private
//    */
//   var inited = false;
//
//   /**
//    * Initializes the class.
//    * @public
//    */
//   var init = function () {
//
//     // Abort if already initialized
//     if (inited) {
//       return false;
//     }
//
//     const $toggles = $('.toggler');
//
//     $toggles.forEach((item) => {
//
//       item.addEventListener('click', (e)=>{
//         e.preventDefault();
//
//         let $id = item.dataset.toggle;
//         const $boxes = $('.our-mission[data-box]');
//
//         $boxes.forEach((box)=>{
//
//           let $boxId = box.dataset.box;
//           let $toggle = $toggles.filter((toggle) => { return toggle.dataset.toggle === $boxId })[0];
//
//           if ( $boxId == $id) {
//
//             item.classList.add('is-active');
//
//
//
//
//             setTimeout(() => {
//               box.classList.add('is-active');
//
//               setTimeout(() => {
//                 box.classList.add('is-shown');
//               }, 50);
//
//             }, 600);
//
//             document.querySelector('.block-mission').classList.add('vision-blue');
//
//           } else {
//             box.classList.remove('is-shown');
//
//             document.querySelector('.block-mission').classList.remove('vision-blue');
//
//             $toggle.classList.remove('is-active');
//
//             setTimeout(() => {
//               box.classList.remove('is-active');
//             }, 600);
//
//           }
//
//         });
//
//       });
//     });
//
//
//
//
//
//     inited = true;
//
//     return true;
//
//   };
//
//   // Expose public methods & properties
//   return {
//     init: init,
//   };
//
// }());
"use strict";