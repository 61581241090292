'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Slider006 = function Slider006() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    // return false;

    inited = true;

    var $slider = document.querySelector('.slider006-slider');

    if ($slider) {
      new SimpleBar($slider, {
        autoHide: false,
        forceVisible: 'x',
        scrollbarMaxSize: 285
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();