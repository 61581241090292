// window.site = (window.site || {});
//
// /**
//  * Init AjaxPagination related scripts
//  * @class Site
//  * @static
//  */
// site.scrollTo = (function scrollTo() {
//
//     'use strict';
//
//     /**
//      * jQuery elements
//      * @private
//      */
//
//     /**
//      * Has the class been initialized?
//      * @private
//      */
//     let inited = false;
//
//     /**
//      * Initializes the class.
//      * @public
//      */
//     const init = function () {
//
//         // Abort if already initialized
//         if (inited) {
//             return false;
//         }
//
//         inited = true;
//
//         const $scrollLinks = [].slice.call(document.querySelectorAll('[data-scroll-to]'));
//
//         $scrollLinks.forEach(function (item) {
//
//           item.addEventListener('click', function (e) {
//             e.preventDefault();
//
//             const selector = item.getAttribute('href').replace('/', '#').toLowerCase();
//
//             console.log(selector);
//
//             let $zone = null;
//
//             if (selector.includes('#')) {
//               $zone = document.querySelector(selector);
//             } else {
//               $zone = document.querySelector('#' + selector);
//             }
//
//             console.log($zone);
//             const topPos = $zone.getBoundingClientRect().top + window.scrollY;
//
//             // alert((topPos - 55));
//
//             // this changes the scrolling behavior to "smooth"
//             window.scroll({
//               top: topPos,
//               behavior: "smooth"
//             });
//           });
//         });
//
//         return true;
//
//     };
//
//     // Expose public methods & properties
//     return {
//         init: init,
//     };
//
// }());
"use strict";